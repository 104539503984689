@font-face {
  font-family: "Quicksand";
  src: local("Quicksand-Bold"),
    url("./assets/fonts/Quicksand-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand-SemiBold"),
    url("./assets/fonts/Quicksand-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand-Medium"),
    url("./assets/fonts/Quicksand-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand-Regular"),
    url("./assets/fonts/Quicksand-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand-Light"),
    url("./assets/fonts/Quicksand-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: "PPWoodland";
  src: local("PPWoodland-Heavy"),
    url("./assets/fonts/PPWoodland-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "PPWoodland";
  src: local("PPWoodland-Bold"),
    url("./assets/fonts/PPWoodland-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}



@font-face {
  font-family: "PPWoodland";
  src: local("PPWoodland-Medium"),
    url("./assets/fonts/PPWoodland-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PPWoodland";
  src: local("PPWoodland-Regular"),
    url("./assets/fonts/PPWoodland-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PPWoodland";
  src: local("PPWoodland-Light"),
    url("./assets/fonts/PPWoodland-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PPWoodland";
  src: local("PPWoodland-Ultralight"),
    url("./assets/fonts/PPWoodland-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
